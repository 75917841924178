const applicationConfig = {
    urls: {
        api : 'https://babyclub-api.solis-consult.ru',
        api_dev : 'http://localhost/babyclub-api'
    },
    auth: {
        username: 'admin',
        password: 'admin'
    }
};

export default applicationConfig;
