import React, {Component} from 'react';
import {FuseSplashScreen} from '@fuse';
import {connect} from 'react-redux';
import * as userActions from 'app/auth/store/actions';
import {bindActionCreators} from 'redux';
import * as Actions from 'app/store/actions';
import firebaseService from 'app/services/firebaseService';
import basicService from 'app/services/basicAuthService';

class Auth extends Component {

    state = {
        waitAuthCheck: true
    }

    componentDidMount()
    {
        return Promise.all([
            // Comment the lines which you do not use
            // this.firebaseCheck(),
            this.basicCheck()
        ]).then(() => {
            this.setState({waitAuthCheck: false})
        })
    }

    basicCheck = () => new Promise(resolve => {

        basicService.init(
            success => {
                if ( !success )
                {
                    resolve();
                }
            }
        );

        basicService.onAuthStateChanged(authUser => {
            if ( authUser )
            {
                this.props.showMessage({message: 'Вход в систему...'});

                this.props.setUserDataBasic(authUser);

                resolve();

                this.props.showMessage({message: 'Успешный вход в систему'});
            }
            else
            {
                resolve();
            }
        });

        return Promise.resolve();
    })

    firebaseCheck = () => new Promise(resolve => {
        firebaseService.init(
            success => {
                if ( !success )
                {
                    resolve();
                }
            }
        );

        firebaseService.onAuthStateChanged(authUser => {
            if ( authUser )
            {

                this.props.showMessage({message: 'Авторизация...'});

                /**
                 * Retrieve user data from Firebase
                 */
                firebaseService.getUserData(authUser.uid).then(user => {

                    this.props.setUserDataFirebase(user, authUser);

                    resolve();

                    this.props.showMessage({message: 'Успешный вход в систему'});
                }, error => {

                    resolve();
                })
            }
            else
            {
                resolve();
            }
        });

        return Promise.resolve();
    })

    render()
    {
        return this.state.waitAuthCheck ? <FuseSplashScreen/> : <React.Fragment children={this.props.children}/>;
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
            logout             : userActions.logoutUser,
            setUserData        : userActions.setUserData,
            setUserDataFirebase: userActions.setUserDataFirebase,
            setUserDataBasic: userActions.setUserDataBasic,
            showMessage        : Actions.showMessage,
            hideMessage        : Actions.hideMessage
        },
        dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);
