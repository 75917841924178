import {authRoles} from 'app/auth';
import React from 'react';

export const EncashAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth    : authRoles.staff,
    routes  : [
        {
            path     : '/encash',
            component: React.lazy(() => import('./EncashApp'))
        },
    ]
};
