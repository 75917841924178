import {authRoles} from 'app/auth';
import React from 'react';

export const TicketAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth    : authRoles.staff,
    routes  : [
        {
            path     : '/tickets',
            component: React.lazy(() => import('./TicketApp'))
        },
    ]
};
