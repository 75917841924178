import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseUtils} from '@fuse';
import {EncashAppConfig} from 'app/main/encash/EncashAppConfig';
import {TicketAppConfig} from 'app/main/tickets/TicketAppConfig';
import {SaleAppConfig} from 'app/main/sales/SaleAppConfig';
import {ZReportAppConfig} from 'app/main/zreports/ZReportAppConfig';
import {CustomersAppConfig} from 'app/main/customers/CustomersAppConfig';
import {UsersAppConfig} from 'app/main/users/UsersAppConfig';
import {LoginConfig} from 'app/main/login/LoginConfig';
import {LogoutConfig} from 'app/main/logout/LogoutConfig';
import {Error404PageConfig} from 'app/main/pages/errors/404/Error404PageConfig';
import {Error500PageConfig} from 'app/main/pages/errors/500/Error500PageConfig';

const routeConfigs = [
    EncashAppConfig,
    TicketAppConfig,
    SaleAppConfig,
    ZReportAppConfig,
    CustomersAppConfig,
    UsersAppConfig,
    LoginConfig,
    LogoutConfig,
    Error404PageConfig,
    Error500PageConfig
];

const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path     : '/',        
        exact    : true,
        component: () => <Redirect to="/zreports"/>
    },
    {
        component: () => <Redirect to="/pages/errors/error-404"/>
    }
];

export default routes;
