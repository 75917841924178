const prodConfig = {
    apiKey: "AIzaSyCL7KnuC4a6PWiB7tMtgnIImCPeq8EqF2E",
    authDomain: "babyclub-3d12a.firebaseapp.com",
    databaseURL: "https://babyclub-3d12a.firebaseio.com",
    projectId: "babyclub-3d12a",
    storageBucket: "",
    messagingSenderId: "755332273458",
    appId: "1:755332273458:web:0441a44991bedd04"
};

const devConfig = {
    apiKey: "AIzaSyCL7KnuC4a6PWiB7tMtgnIImCPeq8EqF2E",
    authDomain: "babyclub-3d12a.firebaseapp.com",
    databaseURL: "https://babyclub-3d12a.firebaseio.com",
    projectId: "babyclub-3d12a",
    storageBucket: "",
    messagingSenderId: "755332273458",
    appId: "1:755332273458:web:0441a44991bedd04"
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

export default config;
