import * as Actions from '../actions';
import history from '@history';

const currentDate = new Date();

const initialState = {
    periodFrom: new Date(currentDate.setDate(currentDate.getDate() - 1)),
    periodTo: new Date(),
    divisions: [],
    division: 'all',
    routeParams: {},
};

const filterReducer = function (state = initialState, action) {
    switch ( action.type ) {
        case Actions.FILTER_AUTHORIZATION_FAILED: {
            history.push('/login');
            break;
        }
        case Actions.GET_FILTERS: {
            return {
                ...state,
                division: action.payload.division,
                periodFrom: action.payload.periodFrom,
                periodTo: action.payload.periodTo,
            }
        }
        case Actions.GET_FILTER_DIVISIONS_DATA: {
            return {
                ...state,
                divisions: action.payload
            };
        }
        case Actions.SET_FILTER_DIVISION: {
            return {
                ...state,
                division: action.division,
                pageIndex: 0,
                loading: true
            };
        }
        case Actions.SET_FILTER_DATE_PERIOD_FROM: {
            return {
                ...state,
                periodFrom: action.periodFrom,
                pageIndex: 0,
                loading: true
            };
        }
        case Actions.SET_FILTER_DATE_PERIOD_TO: {
            return {
                ...state,
                periodTo: action.periodTo,
                pageIndex: 0,
                loading: true
            };
        }
        default: {
            return state;
        }
    }
};

export default filterReducer;
