import axios from 'axios';

export function getUserData()
{
    const auth = localStorage.getItem("bc-auth-info");
    if (auth) {
        try {
            const userAuth = JSON.parse(window.atob(auth));
            if (userAuth) {
                return userAuth;
            }
        } catch(e) {
            console.error("get user info failed", e);
        }
    }

    return null;
}


export function createRequest(method, url, responseType, params) {
    const authData = getUserData();
    if (authData != null) {
        axios.defaults.headers.common['Authorization'] = 'Basic ' + btoa(authData.userId + ':' + authData.token);
        if (method === 'GET') {
            let data = {
                url: url,
                method: method,       
            };

            if (responseType) {
                data.responseType = responseType;
            }

            if(params) {
                data.params = params;
            }
            
            const request = axios(data);
            return request;
        } else if (method === 'DELETE') {
            const request = axios.delete(url);
            return request;
        } else {
            if (method === 'PUT') {
                axios.defaults.headers.put['Content-Type'] = 'application/json';
            } else {
                axios.defaults.headers.post['Content-Type'] = "application/json";
            }
            let data = {
                url: url,
                method: method,  
                data: params,     
            };
            
            const request = axios(data);
            return request;
        }
    }

    return null;
}