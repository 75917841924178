const prodConfig = {
    apiKey           : "1f4627a8-a5b8-494b-af43-4979ba287078",
    authDomain       : "http://localhost:4000",
    users: [
        {login: "admin", password: "admin"}
    ]
};

const devConfig = {
    apiKey           : "b05dc47e-090c-4791-987d-e62b5b7ee5f7",
    authDomain       : "http://localhost:4000",
    users: [
        {login: "admin", password: "admin"}
    ]
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

export default config;
