import axios from 'axios';
import moment from 'moment';
import { createRequest } from 'app/store/actions/user.actions';
import applicationConfig from 'app/fuse-configs/applicationConfig';

export const SET_FILTER_DATE_PERIOD_FROM = '[GLOBAL] SET DATE PERIOD FROM';
export const SET_FILTER_DATE_PERIOD_TO = '[GLOBAL] SET DATE PERIOD TO';
export const GET_FILTER_DIVISIONS_DATA = '[GLOBAL] GET DIVISIONS DATA';
export const SET_FILTER_DIVISION = '[GLOBAL] SET DIVISION';
export const FILTER_AUTHORIZATION_FAILED = '[GLOBAL] AUTHORIZATION FAILED';
export const GET_FILTERS = '[GLOBAL] GET FILTERS';

axios.defaults.baseURL = applicationConfig.urls.api;

const storageDivisionFilterKey = '5105186b-104a-49e5-ab90-083b41b16931';
const storagePeriodFromFilterKey = '63e4b88a-5055-48d9-a068-56bdada232c5';
const storagePeriodToFilterKey = '17490740-9fa0-4c20-b852-0a507107b897';

export function getDivisionsData() {
    console.log("global get divisions");
    const request = createRequest('GET', '/divisions');

    return (dispatch) => {
        
        if(!request) {
            dispatch({type: FILTER_AUTHORIZATION_FAILED});
        }

        request.then((response) =>
            dispatch({
                type   : GET_FILTER_DIVISIONS_DATA,
                payload: response.data
            })
        )
    };
}

function getDivisionFilter() {
    let division = 'all';
    const filtersString = window.localStorage.getItem(storageDivisionFilterKey);

    if (filtersString !== null) {
        division = filtersString;
    }

    return division;
}

function getPeriodFromFilter() {
    const currentDate = new Date();
    let periodFrom = new Date(currentDate.setDate(currentDate.getDate() - 1));
    const filtersString = window.localStorage.getItem(storagePeriodFromFilterKey);

    if (filtersString !== null) {
        periodFrom = moment(filtersString, 'DD.MM.YYYY').toDate();
    }

    return periodFrom;
}

function getPeriodToFilter() {
    let periodTo = new Date();
    const filtersString = window.localStorage.getItem(storagePeriodToFilterKey);

    if (filtersString !== null) {
        periodTo = moment(filtersString, 'DD.MM.YYYY').toDate();
    }

    return periodTo;
}

export function getFilters() {
    console.log('global filters');

    let filters = {
        division: getDivisionFilter(),
        periodFrom: getPeriodFromFilter(),
        periodTo: getPeriodToFilter(),
    };


    return (dispatch) => {
        dispatch({
            type      : GET_FILTERS,
            payload: filters,
        })
    };
}

export function setDivision(division) {
    console.log('global set division', division);
    window.localStorage.setItem(storageDivisionFilterKey, division);

    return {
        type      : SET_FILTER_DIVISION,
        division: division
    }
}

export function setDatePeriodFrom(date) {
    console.log('global period from', date);
    window.localStorage.setItem(storagePeriodFromFilterKey, moment(date).format('DD.MM.YYYY'));

    return {
        type: SET_FILTER_DATE_PERIOD_FROM,
        periodFrom: date
    };
}

export function setDatePeriodTo(date) {
    console.log('global period to', date);
    window.localStorage.setItem(storagePeriodToFilterKey, moment(date).format('DD.MM.YYYY'));

    return {
        type: SET_FILTER_DATE_PERIOD_TO,
        periodTo: date
    };
}