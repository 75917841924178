import config from './basicAuthServiceConfig';
import applicationConfig from 'app/fuse-configs/applicationConfig';
import axios from 'axios';

axios.defaults.baseURL = applicationConfig.urls.api;

class basicAuthService {

    init (success) {
        success(true);
        return;
    };

    signInWithEmailAndPassword  = (username, password) => {
        return this.requestUser(username, password)
            .then(response => {
                const user = response.data;
                const userAuth = window.btoa(JSON.stringify({userId: user.uid, token: user.token}));
                localStorage.setItem("bc-auth-info", userAuth);

                return user;
            });
    };

    onAuthStateChanged = (callback) => {
        const auth = localStorage.getItem("bc-auth-info");
        if (auth) {
            const userAuth = JSON.parse(window.atob(auth));
            if (userAuth) {
                this.requestUserWithToken(userAuth.userId, userAuth.token)
                    .then(response => {
                        callback(response.data);
                    }).catch(error => {
                        console.log(error);
                        callback(null);
                    });
            } else {
                callback(null);
            }
        } else {
            callback(null); 
        }
    };

    requestUser = (username, password) => {
        let config = {
            headers: {
              "Content-Type": "application/json",
            }
        };

        return axios.post("/users/authenticate", {
                login: username,
                password: password
        }, config);
    };

    requestUserWithToken = (userId, token) => {
        let config = {
            headers: {
              "Content-Type": "application/json",
            }
        };
        
        return axios.post("/users/authenticate", {
                userUID: userId,
                token: token
        }, config);
    };

    logout = () => {
        // remove user from local storage to log user out
        localStorage.removeItem("bc-auth-info");
    };

    getAll = () => {
        return config.users;
    };
}

const instance = new basicAuthService();

export default instance;
