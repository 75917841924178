import {authRoles} from 'app/auth';

const navigationConfig = [
    {
        'id'      : 'applications',
        'title'   : 'Управление',
        'type'    : 'group',
        'icon'    : 'apps',
        'children': [
            {
                'id'   : 'zreport-component',
                'title': 'Z-Отчеты',
                'type' : 'item',
                'icon' : 'whatshot',
                'auth' : authRoles.staff,
                'url'  : '/zreports'
            },
            {
                'id'   : 'ticket-component',
                'title': 'Билеты',
                'type' : 'item',
                'icon' : 'whatshot',
                'auth' : authRoles.staff,
                'url'  : '/tickets'
            },
            {
                'id'   : 'sale-component',
                'title': 'Продажи магазина',
                'type' : 'item',
                'icon' : 'whatshot',
                'auth' : authRoles.staff,
                'url'  : '/sales'
            },
            {
                'id'   : 'encash-component',
                'title': 'Инкассации',
                'type' : 'item',
                'icon' : 'whatshot',
                'auth' : authRoles.staff,
                'url'  : '/encash'
            },
            {
                'id'   : 'customers-component',
                'title': 'Именинники',
                'type' : 'item',
                'icon' : 'whatshot',
                'auth' : authRoles.staff,
                'url'  : '/customers'
            },
            {
                'id'   : 'users-component',
                'title': 'Пользователи',
                'type' : 'item',
                'icon' : 'whatshot',
                'auth' : authRoles.admin,
                'url'  : '/users'
            }
        ]
    }
];

export default navigationConfig;
